<template>
  <v-row>
    <v-col cols="2" class="align-center justify-center d-flex">
      <v-icon x-large color="primary">{{ icon }}</v-icon>
    </v-col>
    <v-col class="text-left">
      <h2 class="c-primary josefin">{{ headerText }}</h2>
      <span><slot></slot></span>
    </v-col>
  </v-row>
</template>

<script>
export default {
name: "ServiceText",
  props: ['icon', 'headerText']
}
</script>

<style scoped>
  h2 {
    text-transform: uppercase;
  }
</style>