<template>
  <v-card>
    <v-card-text class="text--primary px-14 py-10">
      <v-row>
        <v-col class="d-flex align-self-baseline" style="font-size: 1.1rem">
          <span class="raleway">{{ localeDate(date).format("LL") }} </span>
          <span class="mx-3">/</span>
          <div class="c-primary font-weight-bold">{{ category }}</div></v-col>
      </v-row>

      <h3 class="mt-6 mb-6" style="text-align: center;">{{ title }}</h3>
      <div class="text-center"><slot></slot></div>
      <v-row no-gutters class="text-center">
        <v-col><v-btn text color="primary" style="font-weight: bold" class="rounded-0 pa-0" :to="to">MEER</v-btn></v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {localeDate} from "../util/localeDate";
export default {
name: "NewsCard",
  props: ['date', 'category', 'title', 'postedBy', 'to'],
  methods: {
  localeDate
  }
}
</script>

<style scoped>

</style>
