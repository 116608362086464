<template>
  <v-btn-toggle dense borderless background-color="transparent" mandatory class="rounded-0" v-model="language" @change="changeLanguage">
    <v-btn text small value="nl"><span class="flag-icon flag-icon-nl"></span> nl</v-btn>
    <v-btn text small value="en"><span class="flag-icon flag-icon-gb"></span> eng</v-btn>
    <v-btn text small value="de"><span class="flag-icon flag-icon-de"></span> de</v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
name: "LanguageSelector",
  data: () => ({
    language: process.env.VUE_APP_I18N_LOCALE
  }),
  methods: {
    changeLanguage(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        const to = this.$router.resolve({ params: {locale} });
        this.$router.push(to.location);
      }
    }
  },
  mounted() {
    this.language = this.$i18n.locale;
  }
}
</script>

<style scoped>

</style>
