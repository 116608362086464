<template>
  <div style="width: 100%;">
    <v-btn v-if="$vuetify.breakpoint.smAndDown" icon @click="$emit('burger')"><v-icon>mdi mdi-menu</v-icon></v-btn>

  <v-tabs
      v-else
      color="black" background-color="white" slider-color="#91B6BA" slider-size="5"  class="d-flex justify-center">
    <v-menu offset-y open-on-hover open-on-focus nudge-top="6" rounded="0" v-for="(route, routeIdx) in routes" :key="routeIdx">
      <template v-slot:activator="{ on, attrs }">
        <v-tab v-bind="{...attrs, to: {...route.to, params: { locale: $i18n.locale}}}" :exact="(!route.subitems && route.to.path !== '/') " v-on="on">{{ $t(route.text) }}</v-tab>
      </template>
      <v-list dense v-if="route.subitems">
        <v-list-item style="min-height: 0;" class="pa-0" dense exact v-for="(subroute, subrouteIdx) in route.subitems" :key="subrouteIdx">
          <v-btn :to="{...subroute.to, params: { locale: $i18n.locale}}" :exact="subroute.exact" class="navbtn rounded-0 justify-start" min-height="40px" text small block elevation="0">{{ $t(subroute.text) }}</v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-tab style="background-color: var(--v-primary-base)" href="https://app.mews.com/distributor/b432a174-6e19-4e76-b748-ace10112d0fc" target="_blank">
      {{  $t('nav.reserveer') }}</v-tab>
  </v-tabs>
  </div>
</template>

<script>
import routes from '@/data/nav';
export default {
name: "NavigationMenu",
  data() {
    return {
      routes
    }
  }
}
</script>

<style scoped>

</style>