<template>
  <div>
    <!-- Get in Touch -->
    <v-page id="contact" no-auto-height class="d-flex" style="padding-top: 50px;padding-bottom: 75px;" v-if="showGetInTouch">
      <v-container>
        <v-row>
          <v-col class="text-center"><h1 class="dz" style="font-size: 4rem;">Get In Touch</h1></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <getintouch-card icon="mdi mdi-map-marker" :title="$t('contact.onsadres')" :action-text="$t('contact.route')" href="https://goo.gl/maps/gkwzBogSdwg6ATKZ7" target="_blank">
              Voorstraat 45<br/>3231 BE Brielle
            </getintouch-card>
          </v-col>
          <v-col cols="12" md="4">
            <getintouch-card icon="mdi mdi-email" :title="$t('contact.contactinfo')" :action-text="$t('contact.belons')" href="tel:31181725980">
              +31 (0)181 725 980<br/>
              info@poorterhotel.nl
            </getintouch-card>
          </v-col>
          <v-col cols="12" md="4">
            <getintouch-card icon="mdi mdi-account-multiple-outline" :title="$t('contact.frontdesk')" :action-text="$t('contact.mailons')" :to="{name: 'Contact', hash: '#contactform'}">
              info@poorterhotel.nl<br/>
              sales@poorterhotel.nl
            </getintouch-card>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <!-- End Get in Touch -->
    <v-page dark no-auto-height class="d-flex" background-color="#222222" style="padding-bottom: 50px;padding-top: 50px;">
      <v-container>
        <v-row>
          <v-col cols="3" class="d-none d-sm-flex">
            <img :src="require('@/assets/logo-inverse.svg')" style="max-width: 200px;max-height: 150px;width:100%;height:100%;" /><br/>
          </v-col>
          <v-col sm="3">
            <h3 class="c-primary">{{ $t("footer.informatie") }}</h3>
            <p>
              <a :href="require('@/assets/pdf/annulering.pdf')" target="_blank" class="white--text">{{ $t("footer.annuleringsvoorwaarden") }}</a><br/>
              <a :href="require('@/assets/pdf/Cookiebeleid.pdf')" target="_blank" class="white--text">{{ $t("footer.cookiebeleid") }}</a><br/>
              <a :href="require('@/assets/pdf/Huisregels.pdf')" target="_blank" class="white--text">{{ $t("footer.huisregels") }}</a><br/>
              <a :href="require('@/assets/pdf/Plattegrond Brielle hotel.pdf')" target="_blank" class="white--text">{{ $t("footer.plattegrond") }}</a><br/>
              <a :href="require('@/assets/pdf/Pivacy voorwaarden.pdf')" target="_blank" class="white--text">{{ $t("footer.privacy") }}</a>
            </p>
            <p><span class="font-weight-bold">{{ $t("contact.mailons") }}: </span> <a href="mailto:info@poorterhotel.nl">info@poorterhotel.nl</a><br/>
              <span class="font-weight-bold">{{ $t("contact.belons") }}: </span> <a href="tel:31181725980">+31 (0)181 725 980</a><br/></p>
          </v-col>
          <v-col sm="3">
            <h3 class="c-primary" style="text-transform:uppercase;">{{ $t("footer.recenteposts") }}</h3>
            <div v-for="(blog, blogIdx) in blogs.slice(0, 2)" :key="blogIdx">
              <footer-recent-post :id="blog.id" :date="blog.created_at" :title="blog.title"></footer-recent-post>
              <hr style="border: 0; border-top: 1px solid #2B2B2B;margin-top: 10px;margin-bottom: 10px;">
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="3">
            <h3 class="c-primary" style="text-transform:uppercase;">{{ $t('footer.newsletter') }}</h3>
            <v-card flat style="background-color: transparent">
              {{ $t("footer.nieuwtjes") }}
              <v-text-field solo class="elevation-0 mt-3" style="font-size: 1rem" :placeholder="$t('footer.inschrijven')" readonly append-icon="mdi mdi-arrow-right" @click="signupDialog = true" @click:append="signupDialog = true"></v-text-field>
              <a href="https://www.facebook.com/PoorterBoutiqueHotel/" target="_blank"><v-icon>mdi mdi-facebook</v-icon></a>
              <a href="https://www.instagram.com/poorterhotel/" target="_blank"><v-icon>mdi mdi-instagram</v-icon></a>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <v-dialog v-model="signupDialog" max-width="800px">
      <iframe src="https://eepurl.com/hx8UP1" height="700"></iframe>
    </v-dialog>
  </div>
</template>

<script>
import VPage from "@/components/VPage";
import FooterRecentPost from "@/components/FooterRecentPost";
import GetintouchCard from "@/components/GetintouchCard";
import axios from "@/http";
export default {
  name: "Footer",
  components: {VPage, FooterRecentPost, GetintouchCard},
  props: {
    showGetInTouch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      blogs: [],
      signupDialog: false
    }
  },
  async mounted() {
    let resp = await axios.get(`/p/${this.$i18n.locale}/blog/recent`);
    this.blogs = resp.data.data;
  }
}
</script>

<style scoped>

</style>
