<template>
  <div>
  <header-slider></header-slider>
    <!-- Kamers -->
    <v-page id="kamers" background-color="#F1EDED" justify="center" align="center" class="d-flex text-center pt-16">
      <v-container>
        <v-row>
          <v-col><h1 class="dz c-primary" style="font-size: 4rem;">{{ $t("kamers.kamers") }}</h1></v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-carousel height="750" light hide-delimiter-background v-if="$vuetify.breakpoint.smAndUp">
              <v-carousel-item>
                <v-row>
                  <v-col cols="4" class="d-flex flex-grow-1">
                    <room-card :src="rooms.find(x => x.category == 'Cozy').fotos[0]" :header-text="$t('kamers.cozy-header')" :to="{name: 'KamerCategory', params: { category: 'cozy', locale: $i18n.locale}}">
                      {{ $t("kamers.cozyroom") }}
                    </room-card>
                  </v-col>
                  <v-col cols="4" class="d-flex flex-grow-1">
                    <room-card :src="rooms.find(x => x.category == 'Comfort').fotos[0]" :header-text="$t('kamers.comfy-header')" :to="{name: 'KamerCategory', params: { category: 'comfort', locale: $i18n.locale}}">
                      {{ $t("kamers.comfyroom") }}
                    </room-card>
                  </v-col>
                  <v-col cols="4" class="d-flex flex-grow-1">
                    <room-card :src="rooms.find(x => x.category == 'Suites').fotos[0]" :header-text="$t('kamers.suites-header')" :to="{name: 'KamerCategory', params: { category: 'suites', locale: $i18n.locale}}">
                      {{ $t("kamers.suites") }}
                    </room-card>
                  </v-col>
                </v-row>
              </v-carousel-item>
              <v-carousel-item>
                <v-row justify="center">
                  <v-col cols="4" class="d-flex flex-grow-1">
                    <room-card :src="rooms.find(x => x.category == 'Garden').fotos[0]" :header-text="$t('kamers.garden-header')" :to="{name: 'KamerCategory', params: { category: 'garden', locale: $i18n.locale}}">
                      {{ $t("kamers.garden") }}
                    </room-card>
                  </v-col>
                  <v-col cols="4" class="d-flex flex-grow-1">
                    <room-card :src="rooms.find(x => x.category == 'Family').fotos[0]" :header-text="$t('kamers.family-header')" :to="{name: 'KamerCategory', params: { category: 'family', locale: $i18n.locale}}">
                      {{ $t("kamers.family") }}
                    </room-card>
                  </v-col>
                </v-row>
              </v-carousel-item>
            </v-carousel>
            <v-carousel v-else light height="625" hide-delimiter-background>
              <v-carousel-item>
                <room-card :src="rooms.find(x => x.category == 'Cozy').fotos[0]" :header-text="$t('kamers.cozy-header')" :to="{name: 'KamerCategory', params: { category: 'cozy', locale: $i18n.locale}}">
                  {{ $t("kamers.cozy") }}
                </room-card>
              </v-carousel-item>
              <v-carousel-item>
                <room-card :src="rooms.find(x => x.category == 'Comfort').fotos[0]" :header-text="$t('kamers.comfy-header')" :to="{name: 'KamerCategory', params: { category: 'comfort', locale: $i18n.locale}}">
                  {{ $t("kamers.comfy") }}
                </room-card>
              </v-carousel-item>
              <v-carousel-item>
                <room-card :src="rooms.find(x => x.category == 'Suites').fotos[0]" :header-text="$t('kamers.suites-header')" :to="{name: 'KamerCategory', params: { category: 'suites', locale: $i18n.locale}}">
                  {{ $t("kamers.suites") }}
                </room-card>
              </v-carousel-item>
              <v-carousel-item>
                <room-card :src="rooms.find(x => x.category == 'Garden').fotos[0]" :header-text="$t('kamers.garden-header')" :to="{name: 'KamerCategory', params: { category: 'garden', locale: $i18n.locale}}">
                  {{ $t("kamers.garden") }}
                </room-card>
              </v-carousel-item>
               <v-carousel-item>
                 <room-card :src="rooms.find(x => x.category == 'Family').fotos[0]" :header-text="$t('kamers.family-header')" :to="{name: 'KamerCategory', params: { category: 'family', locale: $i18n.locale}}">
                   {{ $t("kamers.family") }}
                 </room-card>
              </v-carousel-item>
            </v-carousel>
          </v-col>

        </v-row>
      </v-container>
    </v-page>
    <!-- End Kamers -->

  <v-page id="home" dark background-color="black" align="center" justify="center" style="padding-top: 150px;padding-bottom: 150px;" class="text-center">
    <v-container :fluid="$vuetify.breakpoint.smAndDown">
      <v-row>
        <v-col><h1 class="dz c-primary" style="font-size: 4rem;">{{ $t("home.poorterhotel") }}</h1></v-col>
      </v-row>
      <v-row justify="center" class="mb-16">
        <v-col cols="12" lg="8">
          {{ $t("home.poorterhotel-text") }}
        </v-col>
      </v-row>
      <v-row class="mb-16" v-if="$vuetify.breakpoint.mdAndUp">
        <v-col cols="6" class="d-flex flex-grow-1">
          <v-img :src="require('@/assets/brasserie.jpg')"></v-img>
        </v-col>
        <v-col cols="6" class="d-flex flex-grow-1">
          <v-img :src="require('@/assets/lounge.jpg')"></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" md="6">
          <service-text icon="mdi mdi-glass-cocktail" header-text="Brasserie">
            {{ $t("home.brasserie-service") }}
          </service-text>
        </v-col>
        <v-col cols="6" md="6">
          <service-text icon="mdi mdi-sofa-outline" header-text="Lounge">
            {{ $t("home.lounge-service") }}
          </service-text>
        </v-col>
      </v-row>
    </v-container>
  </v-page>
  <!-- End home -->

  <!-- Testimonials -->
  <v-page id="testimonials" background-color="#282828" class="text-center d-flex py-16">
    <v-container>
      <v-row>
        <v-col><h1 class="dz c-primary" style="font-size: 4rem;">{{ $t("home.ervaringen") }}</h1></v-col>
      </v-row>
      <v-row>
        <v-col>
          <carousel :per-page="1" :perPageCustom="[[768, 2], [1280, 3]]" :autoplay="true" :loop="true" :autoplayTimeout="5000" :scrollPerPage="$vuetify.breakpoint.smAndDown" :paginationEnabled="false" :navigationEnabled="true" navigationPrevLabel="" navigationNextLabel="">
            <template v-for="(testimonial, testimonialIdx) in testimonials" >
              <slide :key="testimonialIdx">
                <testimonial-card :from="testimonial.title" style="height: 100%;" class="ma-5">
                  <div v-html="testimonial.content"></div>
                </testimonial-card>
              </slide>
            </template>
          </carousel>
        </v-col>
      </v-row>
    </v-container>
  </v-page>
  <!-- End Testimonials -->

  <!-- Nieuws -->
  <v-page id="nieuws" class="d-flex py-16" background-color="#91B6BA" :background="require('@/assets/watermerk.svg?fill=#fff')" style="background-size: 100%;background-position:center;">
    <v-container>
      <v-row>
        <v-col class="text-center"><h1 class="dz" style="font-size: 4rem;">{{ $t("home.ontdekken") }}</h1></v-col>
      </v-row>
      <v-row>

        <v-col  cols="12" sm="12" md="6" lg="4" class="d-flex flex-grow-1" v-for="(blog, blogIdx) in blogs.slice(0, 3)" :key="blogIdx">
          <news-card :date="blog.created_at" :category="blogCategory(blog)" :title="blog.title" :posted-by="blog.auteur" :to="{name: 'BlogSingular', params: {id: blog.id, locale: $i18n.locale}}">
            <span v-linkified v-html="blog.excerpt"></span>
          </news-card>
        </v-col>
      </v-row>
    </v-container>
  </v-page>
  <!-- End nieuws -->

  <Footer show-get-in-touch></Footer>
  </div>
  <!-- End Footer -->

</template>

<script>
import HeaderSlider from "@/components/HeaderSlider";
import VPage from "@/components/VPage";
import ServiceText from "@/components/ServiceText";
import RoomCard from "@/components/RoomCard";
import TestimonialCard from "@/components/TestimonialCard";
import NewsCard from "@/components/NewsCard";
import Footer from "@/views/Footer";
//import blogs from "@/data/blogs";
import Rooms from  "@/data/rooms";
import { Carousel, Slide } from 'vue-carousel';
import testimonials from "../data/testimonials";
import axios from "../http";

export default {
  name: 'Home',
  components: {
    NewsCard,
    TestimonialCard,
    RoomCard,
    ServiceText,
    HeaderSlider,
    VPage,
    Footer,
    Carousel,
    Slide
  },
  data: () => ({
    rooms: Rooms,
    testimonials,
    blogs: []
  }),
  methods: {
    blogCategory(blog) {
      if (!blog.category)
        return this.$i18n.t("algemeen");
      return blog.category.translations[this.$i18n.locale];
    }
  },
  async mounted() {
    let resp = await axios.get(`/p/${this.$i18n.locale}/blog?order=created_at`);
    this.blogs = resp.data.data;
    let resp2 = await axios.get(`/p/${this.$i18n.locale}/review?include_body=1&order=created_at`);
    this.testimonials = resp2.data.data;
  }
}
</script>
