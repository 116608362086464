import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import i18n from "@/i18n";

Vue.use(VueRouter)

const routes = [
  {
    path: '/:locale',
    component: {
      template: "<router-view></router-view>"
    },
    props: (route) => ({ locale: route.params.locale || 'nl' }),
    beforeEnter: (to, from, next) => { // <------------
      const locale = to.params.locale; // 1
      const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','); // 2
      if (!supported_locales.includes(locale)) return next('nl'); // 3
      if (i18n.locale !== locale) { // 4
        i18n.locale = locale;
      }
      return next() // 5
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home
      },
      {
        path: 'kamers',
        name: 'Kamers',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "kamers" */ '../views/Kamers')
      },
      {
        path: 'arrangementen',
        name: 'Arrangementen',
        component: () => import(/* webpackChunkName: "arrangementen" */ '../views/Arrangementen')
      },
      // {
      //   path: '/arrangementen/wandel-arrangement',
      //   name: 'Wandel',
      //   component: () => import(/* webpackChunkName: "arrangementen" */ '../views/Arrangementen/Wandel')
      // },
      // {
      //   path: '/arrangementen/diner-arrangement',
      //   name: 'Diner',
      //   component: () => import(/* webpackChunkName: "arrangementen" */ '../views/Arrangementen/Diner')
      // },
      // {
      //   path: '/arrangementen/golf-arrangement',
      //   name: 'Golf',
      //   component: () => import(/* webpackChunkName: "arrangementen" */ '../views/Arrangementen/Golf')
      // },
      {
        path: 'omgeving',
        name: 'Omgeving',
        component: () => import(/* webpackChunkName: "omgeving" */ '../views/Omgeving')
      },
      {
        path: 'omgeving/geschiedenis',
        name: 'Geschidenis',
        component: () => import(/* webpackChunkName: "omgeving" */ '../views/Omgeving/Geschidenis')
      },
      {
        path: 'omgeving/natuur',
        name: 'Natuur',
        component: () => import(/* webpackChunkName: "omgeving" */ '../views/Omgeving/Natuur')
      },
      {
        path: 'omgeving/shoppen-en-genieten',
        name: 'Shoppen',
        component: () => import(/* webpackChunkName: "omgeving" */ '../views/Omgeving/Shoppen')
      },
      {
        path: 'omgeving/sport-en-recreatie',
        name: 'Sport',
        component: () => import(/* webpackChunkName: "omgeving" */ '../views/Omgeving/Sport')
      },
      {
        path: 'over-ons/brasserie-en-lounge',
        name: 'Brasserie',
        component: () => import(/* webpackChunkName: "overons" */ '../views/Overons/Brasserie')
      },
      {
        path: 'over-ons/restaurant',
        name: 'Restaurant',
        component: () => import(/* webpackChunkName: "overons" */ '../views/Overons/Restaurant')
      },
      // {
      //   path: '/kamers/comfort',
      //   name: 'Comfort',
      //   component: () => import(/* webpackChunkName: "kamers" */ '../views/Kamers/Comfort')
      // },
      // {
      //   path: '/kamers/cozy',
      //   name: 'Cozy',
      //   component: () => import(/* webpackChunkName: "kamers" */ '../views/Kamers/Cozy')
      // },
      // {
      //   path: '/kamers/garden',
      //   name: 'Garden',
      //   component: () => import(/* webpackChunkName: "kamers" */ '../views/Kamers/Garden')
      // },
      // {
      //   path: '/kamers/suites',
      //   name: 'Suites',
      //   component: () => import(/* webpackChunkName: "kamers" */ '../views/Kamers/Suites')
      // },
      // {
      //   path: '/kamers/family',
      //   name: 'Family',
      //   component: () => import(/* webpackChunkName: "kamers" */ '../views/Kamers/Family')
      // },
      {
        path: 'kamer/:id',
        name: 'KamerSingular',
        component: () => import(/* webpackChunkName: "kamers" */ '../views/KamerSingle')
      },
      {
        path: 'kamers/:category',
        name: 'KamerCategory',
        component: () => import(/* webpackChunkName: "kamers" */ '../views/KamerCategory')
      },
      {
        path: 'over-ons',
        name: 'OverOns',
        component: () => import(/* webpackChunkName: "overons" */ '../views/OverOns')
      },
      {
        path: 'over-ons/staff',
        name: 'Staff',
        component: () => import(/* webpackChunkName: "overons" */ '../views/Staff')
      },
      {
        path: 'over-ons/promo',
        name: 'Promo',
        component: () => import(/* webpackChunkName: "overons" */ '../views/Promo')
      },
      {
        path: 'blog/:id',
        name: 'BlogSingular',
        component: () => import(/* webpackChunkName: "blog" */ '../views/BlogSingle')
      },
      {
        path: 'blog',
        name: 'Blog',
        component: () => import(/* webpackChunkName: "blog" */ '../views/Blog')
      },
      {
        path: 'vacature/:id',
        name: 'VacatureSingular',
        component: () => import(/* webpackChunkName: "blog" */ '../views/BlogSingle')
      },
      {
        path: 'vacature',
        name: 'Vacature',
        component: () => import(/* webpackChunkName: "blog" */ '../views/Vacature')
      },

      {
        path: 'contact',
        name: 'Contact',
        component: () => import(/* webpackChunkName: "contact" */ '../views/Contact')
      }
    ]
  },
  { // <---------------
    path: '*',
    redirect() {
      return process.env.VUE_APP_I18N_LOCALE;
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
    return { x: 0, y: 0 }
  }
})

export default router
