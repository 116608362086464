<template>
    <v-card class="text-center py-10" rounded="0" elevation="1">
      <v-row class="mb-8">
        <v-col>
          <v-icon x-large color="primary">{{ icon }}</v-icon>
        </v-col>
      </v-row>
      <h3 class="font-weight-bold">{{ title }}</h3>
      <v-card-text class="text--primary">
        <slot></slot>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn class="rounded-0 font-weight-bold" color="primary" text :to="to" :href="href" :target="target">{{ actionText }}</v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
export default {
name: "GetintouchCard",
  props: ['icon', 'title', 'actionText', 'to', 'href', 'target']
}
</script>

<style scoped>

</style>