import { render, staticRenderFns } from "./FooterRecentPost.vue?vue&type=template&id=c332b2d0&scoped=true&"
import script from "./FooterRecentPost.vue?vue&type=script&lang=js&"
export * from "./FooterRecentPost.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c332b2d0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})
