import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify';
import './plugins/linkify';
import router from './router'
import * as crypto from 'crypto';
import VueI18n from 'vue-i18n';
import i18n from './i18n'
import 'flag-icon-css/css/flag-icon.min.css'

Vue.config.productionTip = false

new Vue({
  store,
  vuetify,
  router,
  i18n,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')

Vue.use(VueI18n);

window.c = crypto;