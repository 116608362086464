import assetLoader from "@/assetloader";
import i18n from "@/i18n";

const generalFacilities = [{icon: require('@/assets/icons/air-conditioner.svg?fill=#91B6BA'), text: 'kamers.faciliteiten.airco'},
    {icon: require('@/assets/icons/bureau.svg?fill=#91B6BA'), text: 'kamers.faciliteiten.bureau'},
    {icon: require('@/assets/icons/regendouche.svg?fill=#91B6BA'), text: 'kamers.faciliteiten.regendouche'},
    {icon: require('@/assets/icons/smart-tv.svg?fill=#91B6BA'), text: 'kamers.faciliteiten.smarttv'},
    {icon: require('@/assets/icons/drink-bottle.svg?fill=#91B6BA'), text: 'kamers.faciliteiten.water'},
    {icon: require('@/assets/icons/minibar.svg?fill=#91B6BA'), text: 'kamers.faciliteiten.minibar'},
    {icon: require('@/assets/icons/coffee-cup.svg?fill=#91B6BA'), text: 'kamers.faciliteiten.koffie'},
    {icon: require('@/assets/icons/wifi.svg?fill=#91B6BA'), text: 'kamers.faciliteiten.wifi'},
    {icon: require('@/assets/icons/verwarming.svg?fill=#91B6BA'), text: 'kamers.faciliteiten.verwarming'},
    {icon: require('@/assets/icons/penenpapier.svg?fill=#91B6BA'), text: 'kamers.faciliteiten.pen'},
    {icon: require('@/assets/icons/verzorging.svg?fill=#91B6BA'), text: 'kamers.faciliteiten.verzorging'}];

const makeSizeIcon = (size) => ([{icon: require('@/assets/icons/meters.svg?fill=#91B6BA'), text: `${size}m²`}]);


export default [
    {
        id: 1,
        category: "Garden",
        fotos: assetLoader(require.context('@/assets/rooms/garden/kamer 1', false, /.jp(e)?g$|.png$/)),
        titel: "Garden Room 1",
        text:
            {
                nl: "In deze ruime en sfeervolle kamer in natuurlijke kleuren en uitzicht op de tuin komt u helemaal tot rust. De luxueuze en goed toegankelijke badkamer met ligbad zorgen dat u hier volledig ontspant. Van de twee Garden rooms is deze kamer zeer toegankelijk voor mensen die minder goed ter been zijn, zonder dat we concessies doen aan het interieur.",
                en: "You can fully relax in this spacious and attractive room in natural colours and with garden views. The luxurious and easily accessible bathroom with bathtub ensures you can fully relax here. Of the two Garden rooms, this room is very accessible for people with reduced mobility, without making concessions to the interior.",
                de: "n diesem geräumigen und stilvollen, in natürlichen Farbtönen gehaltenen Zimmer mit Blick auf den Garten können Sie gänzlich zur Ruhe kommen. Das luxuriöse und gut zugängliche Badezimmer mit Badewanne sorgt dafür, dass Sie sich hier völlig entspannen. Von den beiden Garden Rooms ist vor allem dieses Zimmer sehr gut für weniger mobile Menschen zugänglich, ohne dass wir dabei Zugeständnisse an die Einrichtung gemacht haben."
            },
        faciliteiten: makeSizeIcon(32).concat(generalFacilities).concat([
            {icon: require('@/assets/icons/tuin terras.svg?fill=#91B6BA'), text: i18n.t('kamers.faciliteiten.tuinterras')},
            {icon: require('@/assets/icons/tuinzicht.svg?fill=#91B6BA'), text: i18n.t('kamers.faciliteiten.tuinuitzicht')},
        ])
    },
    {
        id: 2,
        category: "Garden",
        fotos: assetLoader(require.context('@/assets/rooms/garden/kamer 2', false, /.jp(e)?g$|.png$/)),
        titel: "Garden Room 2",
        text: {
            nl: "Deze kamer baadt in het licht, door de vele, grote ramen waardoor u uitzicht heeft op de prachtige tuin. Met de openslaande deuren van uw balkon haalt u de tuinbeleving naar binnen. De rustige, wat zakelijke stijl van deze kamer biedt al het comfort dat u wenst.",
            en: "This room is bathed in sunlight thanks to the many large windows, which offer a view of the beautiful garden. And if you open the patio doors to the balcony, you can experience the garden inside. The quiet, somewhat business-like style of this room offers all the comfort you desire.",
            de: "Dieses Zimmer ist wegen der vielen großen Fenster, die Ihnen einen schönen Blick auf den Garten gewähren, lichtdurchflutet. Und wenn Sie die Flügeltüren zu Ihrem Balkon öffnen, holen Sie sich das Gartenerlebnis in Ihr Zimmer. Der ruhige, etwas nüchterne Stil dieses Zimmers bietet Ihnen allen Komfort, den Sie sich wünschen können."
        },
        faciliteiten: makeSizeIcon(32).concat(generalFacilities).concat([
            {icon: require('@/assets/icons/bad.svg?fill=#91B6BA'), text: "Bad"},
            {icon: require('@/assets/icons/tuinbalkon.svg?fill=#91B6BA'), text: i18n.t('kamers.faciliteiten.tuinbalkon')},
            {icon: require('@/assets/icons/tuinzicht.svg?fill=#91B6BA'), text: i18n.t('kamers.faciliteiten.tuinuitzicht')},
        ])
    },

    {
        id: 3,
        category: "Cozy",
        fotos: assetLoader(require.context('@/assets/rooms/cozy/kamer 3', false, /.jp(e)?g$|.png$/)),
        titel: "Cozy Room 3",
        text: {
            nl: "In deze gezellige kamer ervaart u de pure historie van ons middeleeuwse pand, met alle comfort van vandaag. Het klassieke plafond en de authentieke ramen in combinatie met de stijlvolle, warme kleuren maken dat u zich meteen thuis voelt.",
            en: "In this pleasant room, you will experience the pure history of our medieval building, with all of today’s comforts. The classical ceiling and authentic windows in combination with the stylish, warm colours make you feel right at home. ",
            de: "In diesem gemütlichen Zimmer erleben Sie die pure Geschichte unseres mittelalterlichen Gebäudes mit allem modernen Komfort. Die klassische Decke und die authentischen Fenster sorgen in Kombination mit den stilvollen warmen Farben dafür, dass Sie sich hier sofort zu Hause fühlen. "
        },
        faciliteiten: makeSizeIcon(22).concat(generalFacilities).concat([
            {icon: require('@/assets/icons/tuinzicht.svg?fill=#91B6BA'), text: i18n.t('kamers.faciliteiten.tuinuitzicht')},
        ])
    },
    {
        id: 4,
        category: "Comfort",
        fotos: assetLoader(require.context('@/assets/rooms/comfy/kamer 4', false, /.jp(e)?g$|.png$/)),
        titel: "Comfy Room 4",
        text: {
            nl: "Op de eerste verdieping van ons hotel bevinden zich de Comfy rooms; twee ruime kamers met een hoog plafond. Deze kamer heeft een stijlvolle, luxe inrichting met onder meer een royaal bad. Het interieur biedt alle comfort die u, zoals de naam al zegt, in een Comfy room mag verwachten. Via een tussendeur is deze kamer eventueel te koppelen aan de andere Comfy room.",
            en: "The Comfy rooms are located on the first floor of our hotel; two spacious rooms with a high ceiling. This room has a stylish, luxurious interior, including a spacious bath. As the name suggests, the interior offers all the comfort you would expect in a Comfy room. This room can be linked to the other Comfy room via a connecting door.",
            de: "Im ersten Geschoss unseres Hotels befinden sich die Comfy Rooms; zwei geräumige Zimmer mit einer hohen Decke. Dieses Zimmer hat eine stilvolle luxuriöse Einrichtung mit unter anderem einer großzügigen Badewanne. Wie die Bezeichnung des Zimmers ja bereits verspricht, bietet Ihnen dessen Einrichtung allen Komfort, den Sie von einem Comfy Room erwarten dürfen. Über eine Zwischentür lässt sich dieses Zimmer eventuell mit dem anderen Comfy Room verbinden."
        },
        faciliteiten: makeSizeIcon(30).concat(generalFacilities).concat([
            {icon: require('@/assets/icons/bad.svg?fill=#91B6BA'), text: "Bad"},
            {icon: require('@/assets/icons/connecting room.svg?fill=#91B6BA'), text: i18n.t('kamers.faciliteiten.connecting')},
        ])
    },
    {
        id: 5,
        category: "Comfort",
        fotos: assetLoader(require.context('@/assets/rooms/comfy/kamer 5', false, /.jp(e)?g$|.png$/)),
        titel: "Comfy Room 5",
        text: {
            nl: "Deze zeer comfortabele kamer heeft, net als de andere Comfy room, een bijzonder ruimtelijke indeling. De mooie, rustige kleuren maken een verblijf in deze luxe ingerichte kamer een waar genot. Met een twin kingsize bed staat alles in het teken van maximaal comfort. Via een tussendeur is deze kamer eventueel te koppelen aan de andere Comfy room.",
            en: "This very comfortable room, like the other Comfy room, has a special spatial layout. Thanks to the beautiful, calm colours, a stay in this luxuriously furnished room will be a true pleasure. With a twin king-size bed, everything is geared towards maximum comfort. This room can be linked to the other Comfy room via a connecting door.",
            de: "Dieses ausgesprochen komfortable Zimmer hat wie auch der andere Comfy Room eine besonders geräumige Einteilung. Die schönen ruhigen Farbtöne machen einen Aufenthalt in diesem luxuriös eingerichteten Zimmer zu einem wahren Genuss. Mit einem Twin-Kingsize-Bett steht alles im Zeichen des maximalen Komforts. Über einen Zwischentür lässt sich dieses Zimmer eventuell mit dem anderen Comfy Room verbinden."
        },
        faciliteiten: makeSizeIcon(30).concat(generalFacilities).concat([
            {icon: require('@/assets/icons/connecting room.svg?fill=#91B6BA'), text: i18n.t('kamers.faciliteiten.connecting')},
        ])
    },
    {
        id: 6,
        category: "Cozy",
        fotos: assetLoader(require.context('@/assets/rooms/cozy/kamer 6', false, /.jp(e)?g$|.png$/)),
        titel: "Cozy Room 6",
        text: {
            nl: "In ons monumentale pand hebben wij een van de ‘opkamers’ omgebouwd tot een gezellige en zeer comfortabele hotelkamer. Deze opmerkelijke ruimte met uitzicht op de tuin bevat veel luxe om uw verblijf zorgeloos en prettig te maken. We weten zeker dat deze huiselijke kamer naar uw zin zal zijn.",
            en: "In our grand building, we have converted one of the upstairs rooms into a pleasant and very comfortable hotel room. This remarkable space with views is full of luxury to make your stay carefree and pleasant. We’re sure this homely room will be to your liking.\n",
            de: "In unserem geschichtsträchtigen Gebäude haben wir eines der Zimmer im Halbgeschoss in ein gemütliches und ausgesprochen komfortables Hotelzimmer umgewandelt. Dieser besondere Raum mit einer schönen Aussicht bietet viel Luxus für einen sorgenfreien und angenehmen Aufenthalt. Wir sind uns sicher, dass Sie sich in diesem heimeligen Zimmer wohl fühlen werden."
        },
        faciliteiten: makeSizeIcon(22).concat(generalFacilities).concat([
        ])
    },
    {
        id: 7,
        category: "Cozy",
        fotos: assetLoader(require.context('@/assets/rooms/cozy/kamer 7', false, /.jp(e)?g$|.png$/)),
        titel: "Cozy Room 7",
        text: {
            nl: "Vanuit deze kamer kijkt u via de grote ramen uit op de eeuwenoude Sint-Catharijnekerk en zijn omgeving. Samen met de historische ambiance van de ruimte, het uitzicht, de sfeervolle kleuren en inrichting voelt u de rijke geschiedenis van het pand. Zo geniet u met volle teugen van het moderne comfort dat deze hotelkamer biedt.",
            en: "The large windows in this room offer magnificent views of the centuries-old Sint-Catharijnekerk and its surroundings. You can sense the building’s rich history through the historic ambience of the space, the views, the attractive colours and furnishings. It allows you to fully enjoy the modern comfort this hotel room offers.",
            de: "In diesem Zimmer bieten die großen Fenster Ihnen einen hübschen Blick auf die jahrhundertealte Sint-Catharijne-Kirche und deren Umgebung. In der historische Ambiente des Zimmers mit seiner schönen Aussicht, seinen stimmungsvollen Farbtönen und seiner heimeligen Einrichtung spüren Sie die reiche Geschichte des Gebäudes. So genießen Sie in vollen Zügen den modernen Komfort, den dieses Hotelzimmer bietet."
        },
        faciliteiten: makeSizeIcon(22).concat(generalFacilities).concat([
            {icon: require('@/assets/icons/tuinzicht.svg?fill=#91B6BA'), text: i18n.t('kamers.faciliteiten.tuinuitzicht')},
        ])
    },
    {
        id: 8,
        category: "Suites",
        fotos: assetLoader(require.context('@/assets/rooms/suites/kamer 8', false, /.jp(e)?g$|.png$/)),
        titel: "Suite 8",
        text: {
            nl: "Op de bovenste etage van het hotel vindt u een verrassende Suite. In deze ruime kamer met hoog balkenplafond heeft u een wijds uitzicht over de omgeving. Maar bovenal bent u hier op de ultieme plek om te relaxen. De inrichting biedt een echt suite-gevoel. Met in deze suite als topstuk het luxe ligbad, dat vrij in de ruimte staat. Natuurlijk is er ook een comfortabele zithoek waarin u zich heerlijk kunt terugtrekken.",
            en: "The Romantic Hidden Suite is located on the hotel’s top floor. This spacious room with its high beamed ceiling offers vast views of the surrounding area. But above all, this is the ultimate place to relax. The décor offers a true suite feeling. The highlight of this suite is the luxury free-standing bathtub. Of course, there is also a comfortable seating area where you can relax.",
            de: "Im obersten Stock des Hotels finden Sie die Romantic Hidden Suite. In diesem geräumigen Zimmer mit einer hohen Dachschräge mit Balken haben Sie eine weite Aussicht auf die Umgebung. Vor allem befinden Sie sich hier jedoch an einem ausgelesenen Ort zur Entspannung. Die Einrichtung verleiht dem Zimmer ein echtes Suitengefühl. Der Blickfänger dieser Suite ist die Luxusbadewanne, die frei im Raum aufgestellt ist. Selbstverständlich gibt es auch eine bequeme Sitzecke, in die Sie sich herrlich zurückziehen können."
        },
        faciliteiten: makeSizeIcon("30-50").concat(generalFacilities).concat([
            {icon: require('@/assets/icons/losstaand bad.svg?fill=#91B6BA'), text: i18n.t('kamers.faciliteiten.losbad')},
            {icon: require('@/assets/icons/tuinzicht.svg?fill=#91B6BA'), text: i18n.t('kamers.faciliteiten.tuinuitzicht')},
        ])
    },
    {
        id: 9,
        category: "Comfort",
        fotos: assetLoader(require.context('@/assets/rooms/suites/kamer 9', false, /.jp(e)?g$|.png$/)),
        titel: "Comfy Room 9",
        text: {
            nl: "Aan de voorkant heeft de top etage een ruime, luxe comfy room. Onder het hoge dak met eeuwenoude balken komt u tot rust in een kamer met modern meubilair. Het kingsize bed, maar bijvoorbeeld ook de heerlijke loungebank en de rest van de inrichting, vormen een luxueuze omgeving om te ontspannen. In de badkamer treft u een dubbele regendouche.",
            en: "At the front, the top floor houses a spacious, luxurious comfy room. Under the high roof with its centuries-old beams, you can relax in a room with modern furniture. The king-size bed, but also the lovely lounge sofa and the rest of the furnishings, form a luxurious environment in which to relax. In the bathroom you will find a double rainfall shower.",
            de: "An der Frontseite der obersten Etage befindet sich eine geräumige Luxussuite. Unter dem hohen Dachstuhl mit seinen jahrhundertealten Balken kommen Sie in einem Zimmer mit modernem Mobiliar zur Ruhe. Das Kingsize-Bett, jedoch auch beispielsweise das herrliche Loungesofa und der Rest der Einrichtung, bilden eine luxuriöse Ambiente zum Entspannen. Im Badezimmer findet sich eine doppelte Regendusche."
        },
        faciliteiten: makeSizeIcon("30-50").concat(generalFacilities).concat([
            {icon: require('@/assets/icons/dubbele douche.svg?fill=#91B6BA'), text: i18n.t('kamers.faciliteiten.dubbeledouche')},
        ])
    },
    {
        id: 10,
        category: "Suites",
        fotos: assetLoader(require.context('@/assets/rooms/suites/kamer 10', false, /.jp(e)?g$|.png$/)),
        titel: "Suite 10",
        text: {
            nl: "Het hoge, steile plafond met zijn eeuwenoude dakspanten geeft deze enorme suite een bijzonder romantische sfeer. De grote, moderne kroonluchter komt goed tot zijn recht in deze ruimtelijke kamer. Om te ontspannen kunt u plaatsnemen in de leuke zithoek met draaifauteuils. Tijdens het douchen geniet u van prachtig zicht op de rest van de suite.",
            en: "The high, steep ceiling with its centuries-old rafters gives this huge loft a particularly romantic atmosphere. The large, modern chandelier comes into its own in this spacious room. Take a seat in the nice sitting area with swivel armchairs and relax. While showering, you have wonderful views of the rest of the suite. ",
            de: "Die hohe steile Dachschräge mit ihren jahrhundertealten Dachsparren verleiht diesem enormen Loft eine besonders romantische Atmosphäre. Der große moderne Kronleuchter kommt in diesem geräumigen Zimmer ausgesprochen gut zur Geltung. Zur Entspannung können Sie in der schöne Sitzecke mit Drehsesseln Platz nehmen. Während des Duschens genießen Sie den prächtigen Blick auf den Rest des Lofts. "
        },
        faciliteiten: makeSizeIcon("30-50").concat(generalFacilities).concat([
            {icon: require('@/assets/icons/tuinzicht.svg?fill=#91B6BA'), text: i18n.t('kamers.faciliteiten.tuinuitzicht')},

        ])
    },
    {
        id: 11,
        category: "Suites",
        fotos: assetLoader(require.context('@/assets/rooms/suites/kamer 11', false, /.jp(e)?g$|.png$/)),
        titel: "Suite 11",
        text: {
            nl: "Deze ruime suite komt nog ruimer over door het hoge plafond met zijn oude balken en indrukwekkende, moderne lampen. Neem plaats in de zithoek en ontspan in twee comfortabele fauteuils. Vanuit het raam heeft u goed zicht op de oude 15de- en 16de-eeuwse panden die zo kenmerkend zijn voor de straat waarin ons hotel is gevestigd. In de badkamer vindt u het zeer luxe sanitair dat u maximaal comfort garandeert. ",
            en: "This spacious suite is made even more spacious by the high ceiling with its old beams and impressive, modern lamps. Take a seat in one of the two comfortable armchairs in the seating area and relax. From the window, you have a good view of the old 15th and 16th-century buildings that are so characteristic of the street in which our hotel is located. The bathroom offers highly luxurious sanitary facilities that guarantee maximum comfort. ",
            de: "Dieser geräumige Loft mutet wegen der hohen Decke mit ihren alten Balken und beeindruckenden modernen Lampen noch geräumiger an. Setzen Sie sich in die Sitzecke und entspannen Sie sich in einem der beiden komfortablen Sessel. Das Fenster bietet einen schönen Blick auf die charakteristischen Gebäude aus dem 15. und 16. Jahrhundert in der Straße unseres Hotels. Im Badezimmer finden Sie ausgesprochen luxuriöse Sanitäreinrichtungen, die Ihnen maximalen Komfort garantieren. "
        },
        faciliteiten: makeSizeIcon("30-50").concat(generalFacilities).concat([
        ])
    },
    {
        id: 12,
        category: "Family",
        fotos: assetLoader(require.context('@/assets/rooms/family', false, /.jp(e)?g$|.png$/)),
        titel: "Family Room",
        text: {
            nl: "Komt u met uw ouders, wilt u dat de kinderen dicht bij u zijn of gewoon van elkaars gezelschap kunnen genieten? Reserveer dan onze Family Rooms. Wij zorgen in dat geval dat beide Comfy Rooms tegelijk voor u beschikbaar zijn. Hier hebben we voldoende bedden om vier personen een luxe en zeer comfortabel verblijf te bezorgen. Via een tussendeur zijn deze kamers met elkaar verbonden.",
            en: "If you’re visiting with your parents, want the children to be close to you or just enjoy each other’s company, then we recommend booking our Family Rooms. In that case, we will ensure that both Comfy Rooms are available to you at the same time. We have enough beds to give four people a luxurious and very comfortable stay. These rooms are connected via a connecting door.",
            de: "Kommen Sie mit Ihren Eltern, möchten Sie, dass die Kinder bei Ihnen in der Nähe sind, oder möchten Sie einfach die Gesellschaft miteinander genießen? Buchen Sie dann unsere Family Rooms. Wir sorgen in diesem Fall dafür, dass beide Comfy Rooms zugleich für Sie verfügbar sind. Dort befinden sich genug Betten für einen luxuriösen und ausgesprochen komfortablen Aufenthalt von vier Personen. Über eine Zwischentür sind diese Zimmer miteinander verbunden."
        },
        faciliteiten: makeSizeIcon(58).concat(generalFacilities).concat([
        ])
    },
]
