<template>
  <v-app>
    <v-system-bar
        app
        dark
        color="transparent"
        height="40"
        :style="{background: `url('${require('./assets/sysbar-bg.jpg')}')`}"
        v-if="$vuetify.breakpoint.mdAndUp"
    >
      <v-container>
      <v-row no-gutters>
        <v-col>
          <v-icon>mdi mdi-map-marker</v-icon>
          <span>Voorstraat 45, Brielle</span>
        </v-col>
        <v-col>
          <v-icon>mdi mdi-email</v-icon>
          <span>info@poorterhotel.nl</span>
        </v-col>
        <v-col>
          <v-icon>mdi mdi-phone</v-icon>
          <span>+31 (0)181 725 980</span>
        </v-col>
        <v-col>
          <span class="mr-3">{{ $t('home.volgons')}}</span>
          <a href="https://www.facebook.com/PoorterBoutiqueHotel/" target="_blank"><v-icon>mdi mdi-facebook</v-icon></a>
          <a href="https://www.instagram.com/poorterhotel/" target="_blank"><v-icon>mdi mdi-instagram</v-icon></a>
        </v-col>
        <v-col>
          <language-selector></language-selector>
        </v-col>
      </v-row>
      </v-container>
    </v-system-bar>
    <v-app-bar
        app
        prominent
        shrink-on-scroll
        color="white"

    >
      <template v-slot:extension>
        <NavigationMenu @burger="drawer = !drawer"></NavigationMenu>
      </template>
      <div style="width: 100%; height: 100%; display: flex; align-content: center; justify-content: center;">
        <img :src="require('./assets/logo.svg')" style="max-height: 150px;height:100%;" />
      </div>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" v-if="$vuetify.breakpoint.mdAndDown">
      <v-row no-gutters align="center" justify="center">
        <v-col class="text-center">
          <img :src="require('./assets/logo.svg')" style="max-height: 150px;height:100%;" />
        </v-col>
      </v-row>
      <v-list dense >
        <div v-for="item in nav" :key="item.text">
          <v-list-item v-if="!item.subitems" :to="{...item.to, params: {locale: $i18n.locale}}" :exact="item.to.path !== '/'" link>
            <v-list-item-content>
              <v-list-item-title v-text="$t(item.text)" style="font-size: 0.9rem;font-weight: bold"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        <v-list-group
            :append-icon="item.subitems ? '$expand' : ''"
            :value="$route.matched.some(({path}) => path.includes($router.resolve(item.to).route.path))"
            no-action
            v-else
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="$t(item.text)" style="font-size: 0.9rem;font-weight: bold"></v-list-item-title>
            </v-list-item-content>
          </template>

          <!-- <v-list-item :to="item.to" exact link>
            <v-list-item-content>
              <v-list-item-title v-text="item.text" style="font-size: 0.9rem;font-weight: bold"></v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <v-list-item
              v-for="child in item.subitems"
              :key="child.text"
              :to="{...child.to, params: {locale: $i18n.locale}}"
              :exact="child.exact"
          >
            <v-list-item-content>
              <v-list-item-title v-text="$t(child.text)" style="font-size: 0.9rem;font-weight: bold"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        </div>
      </v-list>
      <language-selector class="ml-4"></language-selector>
    </v-navigation-drawer>

    <!-- Home -->
    <v-main>
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
  </v-app>
</template>

<script>


import NavigationMenu from "@/components/base/NavigationMenu";
import nav from "@/data/nav";
import LanguageSelector from "@/components/LanguageSelector";
export default {
  name: 'App',

  components: {
    LanguageSelector,
    NavigationMenu

  },

  data: () => ({
    drawer: false,
    nav
  })
};
</script>

<style lang="scss">
  @import "~@/assets/styles/app";
</style>
