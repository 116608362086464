<template>
  <v-card rounded="0">
    <router-link :to="to">
    <v-img
        :src="src"
        :aspect-ratio="4/3"
    ></v-img>
    </router-link>
    <h3 class="mt-6">{{ headerText }}</h3>
    <img :src="require('@/assets/kamer-decal.png')" />
    <v-card-text class="text--primary">
      <slot></slot>
    </v-card-text>
    <v-card-actions class="d-flex justify-center">
      <v-btn text color="primary" style="font-weight: bold" class="rounded-0" :to="to">MEER</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
name: "RoomCard",
  props: ['src', 'headerText', 'to']
}
</script>

<style scoped>

</style>