<template>
  <v-card rounded="0" class="pb-16 pt-16">
    <v-row class="mb-5">
      <v-col><img :src="require('@/assets/quote-decal.png')" /></v-col>
    </v-row>
    <v-card-text class="text--primary">
      <slot></slot>
    </v-card-text>
    <v-row class="mt-16">
      <v-col><img :src="require('@/assets/circle-decal.png')" /></v-col>
    </v-row>
    <h3 class="mt-6">{{ from }}</h3>
  </v-card>
</template>

<script>
export default {
name: "TestimonialCard",
  props: ['from']
}
</script>

<style scoped>

</style>