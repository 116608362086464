export default [
    {text: "nav.home", to: {name: "Home"}, subitems: null},
    {text: "nav.kamers", to: {name: "Kamers"}},
    {text: "nav.arrangementen", to: {name: "Arrangementen"}},
    {text: "nav.omgeving", to: {name: "Omgeving"}, subitems: [
            {text: "nav.omgeving", to: {name: "Omgeving"}, exact: true},
            {text: "nav.geschiedenis", to: {name: "Geschidenis"}},
            {text: "nav.natuurstrand", to: {name: "Natuur"}},
            {text: "nav.shoppengenieten", to: {name: "Shoppen"}},
            {text: "nav.sportrecreatie", to: {name: "Sport"}},
        ]
    },
    {text: "nav.brasserie", to: {name: "Brasserie"}, subitems: [
            {text: "brasserie.ambiance-header", to: {name: "Brasserie", hash: "#ambiance"}},
            {text: "brasserie.relaxen-header", to: {name: "Brasserie", hash: "#relaxen"}},
            {text: "Lunch of high tea", to: {name: "Brasserie", hash: "#lunch"}},
            {text: "Plek om af te spreken", to: {name: "Brasserie", hash: "#afspreken"}},
            {text: "Receptie", to: {name: "Brasserie", hash: "#receptie"}},
            {text: "Brunch", to: {name: "Brasserie", hash: "#brunch"}},
            {text: "Huwelijk", to: {name: "Brasserie", hash: "#huwelijk"}},
            {text: "Vergadermogelijkheden", to: {name: "Brasserie", hash: "#vergader"}},
            {text: "Kerst- en nieuwjaarsborrels", to: {name: "Brasserie", hash: "#nieuwjaar"}},
        ]
    },
    {text: "nav.overons", to: {name: "OverOns"}, subitems: [
            {text: "nav.overons", to: {name: "OverOns"}, exact: true},
            {text: "nav.promo", to: {name: "Promo"}},
            {text: "nav.staff", to: {name: "Staff"}},
            
        ]
    },
    {text: "nav.ontdekken", to: {name: "Blog", path: "/Blog"}, subitems: null},
    {text: "nav.vacature", to: {name: "Vacature", path: "/Vacature"}, subitems: null},
    {text: "nav.contact", to: {name: "Contact"}, subitems: null},
]
