<template>
  <v-card flat style="background-color: transparent">
    <router-link :to="{name: 'BlogSingular', params: {id} }"><h3 class="white--text">{{ title }}</h3></router-link>
    <span class="font-weight-bold muted" style="font-size: 1.1rem;">{{ displayDate }}</span>
  </v-card>
</template>

<script>
import {localeDate} from "../util/localeDate";
export default {
name: "FooterRecentPost",
  props: ['id', 'date', 'title'],
  computed: {
    displayDate() {
      return localeDate(this.date).format('LL')
    }
  }
}
</script>

<style scoped>

</style>