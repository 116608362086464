<template>
  <v-card :class="classes" :style="css" v-bind="$attrs">
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  name: "VPage",
  props: {
    background: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: 'white'
    },
    align: {
      type: String,
      default: "center"
    },
    justify: {
      type: String,
      default: "center"
    },
    noAutoHeight: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      let c = ['page', `justify-${this.justify}`, `align-${this.align}`, 'elevation-0', 'rounded-0'];
      if (this.noAutoHeight)
        c.push('noautoheight');
      return c;
    },
    css() {
      return {backgroundImage: `url('${this.background}')`, backgroundColor: `${this.backgroundColor}` };
    }
  }
}
</script>

<style scoped>
.page {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: contain;
}
.page.noautoheight {
  min-height: initial;
}
</style>
