export default [
    {
        text: "Heel mooi hotel, geeft een luxe gevoel. Tot in de perfectie.",
        from: "Wen & Den"
    },
    {
        text: "Heerlijk gastvrij verblijf, bedankt voor de heerlijke nacht. Lekker gegeten en geslapen het is een aanrader voor een weekend Brielle. Je komt hier tot rust!",
        from: "Geert Jan & Armanda"
    },
    {
        text: "Wauw, of we in Londen zijn! Heerlijk gegeten en geslapen we hebben genoten!",
        from: "Joost & Yvonne"
    },
    {
        text: "We zijn helemaal in de watten gelegd",
        from: "Cyrilla"
    },
    {
        text: "We gaan nog een keer.",
        from: "Gerbrand"
    },
    {
        text: "Een rustig verblijf in een authentiek gemoderniseerd hotel in het centrum van een idyllisch stadje.",
        from: "Danny"
    },
    {
        text: "Een heerlijk bed, ontbijt. En een meesterlijke douche!",
        from: "Jeannine"
    },
    {
        text: "Ouh la la Marie Stella Maris !",
        from: "Marcel & Marleen"
    },
    {
        text: "Heel mooi & fijn verblijf!",
        from: "Inge & Youri"
    },
    {
        text: "Het welkome gevoel zit ‘m in de details!",
        from: "André & Gemma"
    },
    {
        text: "Wat een prachtig hotel !!!",
        from: "Han & Pascale"
    },
    {
        text: ""
    }
].reverse().splice(0, 9);
