<template>
  <v-carousel v-model="model" height="calc(100vh - 224px - 40px)" cycle hide-delimiter-background hide-delimiters :show-arrows="false">
    <v-carousel-item
        v-for="(item, itemIdx) in slides"
        :key="itemIdx"
        :src="item"
    >
      <template slot="default">
        <v-row class="d-flex align-center" style="height: 100%">
          <v-col cols="6" offset="3" class="shadowed text-center">
            <h2 class="white--text maintext d-none d-xl-flex" style="font-size:6rem;">A historical stay in the comfort of today!</h2>
            <h2 class="white--text maintext d-none d-lg-flex d-xl-none" style="font-size:4rem;">A historical stay in the comfort of today!</h2>
            <h2 class="white--text maintext d-lg-none d-xl-none" style="font-size:3rem;">A historical stay in the comfort of today!</h2>
            <v-btn color="primary" x-large class="mt-6 rounded-0" style="color: black; font-weight: bold" href="https://app.mews.com/distributor/b432a174-6e19-4e76-b748-ace10112d0fc" target="_blank">
              {{  $t('reserveren') }}</v-btn>
          </v-col>
        </v-row>
      </template>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "HeaderSlider",
  props: {
    /*slides: {
      type: Array
    }*/
  },
  data() {
    return {
      model: 0,
      slides: [
        require('../assets/header.jpg'),
      ]
    }
  }
}
</script>

<style scoped>
  .maintext {
    font-family: "DeathZone", sans-serif;
    font-weight: 400;
  }
</style>